import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";

config.rawError = true;


interface RootState {
  graphClient: any; // Replace 'any' with the actual type of your graphClient
}


const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ThemeModeModule,
  },
  state: {
    graphClient: null,
  },
  mutations: {  
    setGraphClient(state, client) {
      console.log("index.ts - Setting graph client in state:", client);
      state.graphClient = client;
    },
  },
  actions: {
    updateGraphClient({ commit }, client) {
      console.log("index.ts - Updating graph client:", client);
      commit('setGraphClient', client);
    },
  },
  getters: {
    graphClient: (state) => state.graphClient, // Access the graphClient from the state

  },
});

export default store;


// https://devblogs.microsoft.com/azure-sdk/vue-js-user-authentication/